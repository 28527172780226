<div class="account-container">

    <div *ngIf="step1">
        <div class="account-logo">
            <a id="header-logo">
                <img [defaultImage]="'assets/images/product/placeholder.jpg'" [lazyLoad]="logourl" class="img-fluid"
                    alt="logo">
            </a>
        </div>
        <h4 class="account-title">{{'account login'|translate}}</h4>
        <div class="login-type-container"
            *ngIf="settings?.register_client_on_email==1&&settings?.register_client_on_mobile==1">
            <div class="login-type-mobile " [class.active]="isMobileLogin" (click)="toggleLoginType(true)">
                <span>{{'account phone'|translate}}</span>
            </div>
            <div class="login-type-mail" [class.active]="isEmailLogin" (click)="toggleLoginType(false)">
                <span>{{'account email'|translate}}</span>
            </div>
        </div>
        <p *ngIf="isMobileLogin && settings?.register_client_on_mobile==1 " class="my-3 text-center d-block">{{'account'|translate}}</p>
        <p *ngIf="isEmailLogin||(settings?.register_client_on_email==1&&settings?.register_client_on_mobile==0)"
            class="my-3 text-center d-block">{{'account send code email'|translate}}
        </p>

        <div class="inputs" *ngIf="isMobileLogin && settings?.register_client_on_mobile==1 ">
            <p class="single-input mobile-input mobile-p input-placeholder" [dir]="Common.isRtl?'rtl':'ltr'">
                <input [placeholder]="phoneplaceholder" type="tel" [(ngModel)]="mobile_data" class="w-100 form-control"
                    [class.is-valid]="mobileIsValid" [class.is-invalid]="mobileIsInValid"
                    (ngModelChange)="checkLength()">
            </p>
            <div class="mobile-code">
                <span>{{country_code}}+</span>
                <img [src]="image_mobile" alt="flag-code">
            </div>

        </div>
        <div class="errorOccurred" *ngIf="errorOccurred &&isMobileLogin && settings?.register_client_on_mobile==1">
            {{errorOccurredMessage}}
        </div>
        <div class="inputs"
            *ngIf="isEmailLogin||(settings?.register_client_on_email==1&&settings?.register_client_on_mobile==0)">
            <p class="single-input email-input input-placeholder" [dir]="Common.isRtl?'rtl':'ltr'">
                <input placeholder="{{'enter email'|translate}}" [dir]="Common.isRtl?'rtl':'ltr'"
                    [(ngModel)]="email_data" type="email" class="email-input w-100">
            </p>

        </div>
        <div class="errorOccurred"
            *ngIf="errorOccurred &&isEmailLogin||(settings?.register_client_on_email==1&&settings?.register_client_on_mobile==0)">
            {{errorOccurredMessage}}
        </div>

        <button (click)="registerForm()" class="btn account-btn mb-2" [style.color]="calculateTextColor()">{{'button send'|translate}}</button>


    </div>
    <div class="d-flex justify-content-between flex-column h-100" *ngIf="step2">

        <div>
            <div class="text-right">
                <i class="fa fa-solid fa-angle-right account-icon" (click)="goBackTO(1)"></i>
            </div>
            <div>
                <img src="./assets/images/otp.gif" class="mt-3 otp-image" alt="otp image">
                <h4 class="mt-3 text-center account-title ">{{ 'please_enter_verification_code' |
                    translate }}</h4>
                <div class="account-content-message" [class.account-content-message-rtl]="Common.isRtl">
                    <p>{{ 'account message' | translate }}</p>
                    <p>{{mobile_data}}</p>
                </div>
            </div>

            <div class="verify-code" [ngStyle]="{'flex-direction':Common.isRtl ?'row':'row-reverse'}">
                <div class="verify-code-item" *ngFor="let item of [0, 1, 2, 3, 4, 5]; let i = index">
                    <input #inputFields type="text" maxlength="1" (input)="moveToInput($event, i)"
                        (keydown)="moveToInput($event, i)">
                </div>
            </div>
            <div class="py-3 account-timer justify-content-center" *ngIf="formatTime(totalSeconds) != '0:00'"
                [ngStyle]="{'flex-direction':Common.isRtl ?'row':'row-reverse'}">
                <span class="text-center">{{ 'timer message' | translate }}</span>
                <span>{{ formatTime(totalSeconds) }}</span>
                <span>{{ 'time.seconds'|translate }}</span>
            </div>
            <button class="mx-auto mt-3 btn send-code-btn d-block mb-2" [style.color]="calculateTextColor()"
                *ngIf="formatTime(totalSeconds) == '0:00'" (click)="sendCode(); startTimer();">{{ 'send again' | translate
                }}</button>
        </div>
        <button (click)="checkcode()" class="btn account-btn mb-2" [style.color]="calculateTextColor()">{{ 'button confirm' |
            translate }}</button>
    </div>

    <div *ngIf="step3">
        <div class="text-right">
            <i class="fa fa-solid fa-angle-right account-icon" (click)="goBackTO(1)"></i>
        </div>
        <div>
            <h3 class="password-title">{{'Enter Password'|translate}}</h3>
        </div>
        <div class="my-3 inputs">
            <p class="single-input" [dir]="Common.isRtl ? 'rtl' : 'ltr'">
                <input [type]="passwordFieldType" class="w-100 password-input" [(ngModel)]="password" placeholder=" "
                    [dir]="Common.isRtl ? 'rtl' : 'ltr'">
                <label [class.label-rtl]="Common.isRtl">{{ 'Enter Password' | translate }}</label>
                <i class="fa fa-solid fa-lock p-icon"></i>
                <i class="fa visible-icon" [ngClass]="passwordFieldType === 'password' ? 'fa-eye' : 'fa-eye-slash'"
                    (click)="togglePasswordVisibility('password')"></i>
            </p>
        </div>
        <div>
            <!-- (click)="goBackTO(2)" -->
            <a class="cursor-pointer">
                <p class="forget-password">{{'forget-password'|translate}}</p>
            </a>
        </div>
        <button class="btn account-btn mb-2" [style.color]="calculateTextColor()" (click)="doneSign()">{{'button send'|translate}}</button>
        <div class="errorOccurred" *ngIf="errorOccurred">
            {{errorOccurredMessage}}
        </div>
    </div>

    <div *ngIf="step4">
        <div class="text-right">
            <i class="fa fa-solid fa-angle-right account-icon" (click)="goBackTO(1)"></i>
        </div>
        <div>
            <h3 class="my-4 text-center account-create--title">{{'last step login'|translate}}</h3>

        </div>
        <div class="flex-row-reverse row" [class.flex-row-reverse]="!Common.isRtl">
            <p class="mb-3 text-left d-block" [class.text-right]="Common.isRtl">{{'add info login'|translate}}</p>
            <div class="login-type-container mb-3">
                <div class="login-type-mobile " [class.active]="isClient" (click)="toggleMembershipType(true)">
                    <span>{{'client'|translate}}</span>
                </div>
                <div class="login-type-mail" [class.active]="isContributor" (click)="toggleMembershipType(false)">
                    <span>{{'contributor'|translate}}</span>
                </div>
            </div>
            <div class="row" *ngIf="isContributor">
                <div class="my-2 col-12">
                    <div class="inputs">
                        <p class="single-input" [dir]="Common.isRtl?'rtl':'ltr'">
                            <input type="text" class="w-100" [(ngModel)]="sharebox" placeholder=" "
                                [dir]="Common.isRtl?'rtl':'ltr'" [disabled]="contributorExists">
                            <label [class.label-rtl]="Common.isRtl">
                                {{'sharebox'|translate}} </label>
                            <i class="fa fa-solid fa-archive p-icon"></i>
                        </p>
                    </div>
                    <span class="errorOccurred" *ngIf="shareboxErr">{{ contributorErrMsg  | translate}}</span>
                </div>
                <div class="my-2 col-12">
                    <div class="inputs">
                        <p class="single-input" [dir]="Common.isRtl?'rtl':'ltr'">
                            <input type="text" class="w-100" [(ngModel)]="civilId" placeholder=" "
                                [dir]="Common.isRtl?'rtl':'ltr'" [disabled]="contributorExists">
                            <label [class.label-rtl]="Common.isRtl">
                                {{'civilId'|translate}} </label>
                            <i class="fa fa-solid fa-user p-icon"></i>
                        </p>
                    </div>
                    <span class="errorOccurred" *ngIf="cvilidErr">{{ contributorErrMsg | translate }}</span>
                </div>
            </div>
            <div class="row" *ngIf="isClient || contributorExists">
                <div class="my-2 col-6">
                    <div class="inputs">
                        <p class="single-input" [dir]="Common.isRtl?'rtl':'ltr'">
                            <input type="text" class="w-100" [(ngModel)]="user_name" placeholder=" "
                                [dir]="Common.isRtl?'rtl':'ltr'" [disabled]="contributorExists">
                            <label [class.label-rtl]="Common.isRtl">
                                {{'first name'|translate}} </label>
                            <i class="fa fa-solid fa-user p-icon"></i>
                        </p>
    
                    </div>
                </div>
                <div class="my-2 col-6">
                    <div class="inputs">
                        <p class="single-input" [dir]="Common.isRtl?'rtl':'ltr'">
                            <input type="text" class="w-100" [(ngModel)]="last_name" placeholder=" "
                                [dir]="Common.isRtl?'rtl':'ltr'" [disabled]="contributorExists">
                            <label [class.label-rtl]="Common.isRtl">
                                {{'last name'|translate}} </label>
                            <i class="fa fa-solid fa-user p-icon"></i>
                        </p>
                    </div>
                </div>
                <p class="errorOccurred" *ngIf="successUser_name">{{errorUserNameMessage}}</p>
                <p class="errorOccurred" *ngIf="successLast_name">{{errorLastNameMessage}}</p>
                <div class="my-2 col-12">
                    <div class="inputs" *ngIf="(isEmailLogin && settings?.register_client_on_email== 1) || contributorExists">
                        <p class="single-input  " [dir]="Common.isRtl?'rtl':'ltr'">
                            <input type="tel" class="w-100" [(ngModel)]="mobile_data"
                                [placeholder]="phoneplaceholder" (ngModelChange)="checkLength()" 
                                [disabled]="contributorExists"
                                [class.is-valid]="mobileIsValid" [class.is-invalid]="mobileIsInValid">
                            <label [class.label-rtl]="Common.isRtl">
                                {{'enter phone'|translate}} </label>
                        </p>
                        <div class="mobile-code">
                            <span>{{country_code}}+</span>
                            <img [src]="image_mobile" alt="flag-code">
                        </div>
                    </div>
                    <span class="errorOccurred" *ngIf="successMobile">{{errorMobileMessage}} </span>
                    <div class="inputs my-2" *ngIf="(isMobileLogin && settings?.register_client_on_mobile==1) || contributorExists">
                        <p class="single-input" [dir]="Common.isRtl?'rtl':'ltr'">
                            <input type="email" class="w-100" [(ngModel)]="email_data" placeholder=" "
                                [dir]="Common.isRtl?'rtl':'ltr'" [disabled]="contributorExists">
                            <label [class.label-rtl]="Common.isRtl">
                                {{'enter mail optional'|translate}} </label>
                            <i class="fa fa-solid fa-envelope p-icon"></i>
                        </p>
                    </div>
                    <span class="errorOccurred" *ngIf="successEmail">{{errorSuccessEmailMessage}} </span>
                </div>
                <p class="mt-4 mb-3 text-left d-block" [class.text-right]="Common.isRtl">{{'create password'|translate}}
                </p>
                <div class="my-2 col-12">
                    <div class="inputs">
                        <p class="single-input" [dir]="Common.isRtl ? 'rtl' : 'ltr'">
                            <input [type]="passwordFieldType" class="w-100 password-input" [(ngModel)]="password"
                                placeholder=" " [dir]="Common.isRtl ? 'rtl' : 'ltr'" >
                            <label [class.label-rtl]="Common.isRtl">{{ 'Enter Password' | translate }}</label>
                            <i class="fa fa-solid fa-lock p-icon"></i>
                            <i class="fa visible-icon"
                                [ngClass]="passwordFieldType === 'password' ? 'fa-eye' : 'fa-eye-slash'"
                                (click)="togglePasswordVisibility('password')"></i>
                        </p>
                    </div>
                    <span class="errorOccurred" *ngIf="successPassword">{{ errorPasswordMessage }}</span>
                </div>
                <div class="my-2 col-12">
                    <div class="inputs">
                        <p class="single-input" [dir]="Common.isRtl ? 'rtl' : 'ltr'">
                            <input [type]="confirmPasswordFieldType" class="w-100 password-input"
                                [(ngModel)]="confirmPassword" placeholder=" " [dir]="Common.isRtl ? 'rtl' : 'ltr'">
                            <label [class.label-rtl]="Common.isRtl">{{ 'confirm password' | translate }}</label>
                            <i class="fa fa-solid fa-lock p-icon"></i>
                            <i class="fa visible-icon"
                                [ngClass]="confirmPasswordFieldType === 'password' ? 'fa-eye' : 'fa-eye-slash'"
                                (click)="togglePasswordVisibility('confirmPassword')"></i>
                        </p>
                    </div>
                    <span class="errorOccurred" *ngIf="successconfirmPassword">{{ errorconfirmPasswordMessage }}</span>
                </div>
            </div>

        </div>
        <span class="errorOccurred" *ngIf="contributorNoMobileErr">{{ contributorNoMobileErrMsg | translate }}</span>
        <span class="errorOccurred" *ngIf="noContributorAccErr">{{ noContributorAccErrErrMsg | translate }}</span>
        <span class="errorOccurred" *ngIf="errorOccurred">
            {{errorOccurredMessage}}
        </span>
        <button (click)="createAccount()" *ngIf="isClient" class="mt-3 btn account-btn mb-2" [style.color]="calculateTextColor()">{{'create Account'|translate}}</button>
        <button (click)="createContributorAccount()" *ngIf="!isClient && !contributorExists" class="mt-3 btn account-btn mb-2" [style.color]="calculateTextColor()">{{'checkContributor'|translate}}</button>
        <button (click)="sendCode()" *ngIf="contributorExists" class="mt-3 btn account-btn mb-2" [style.color]="calculateTextColor()">{{'send code'|translate}}</button>
    </div>

    <div class="d-flex flex-column justify-content-between h-100" *ngIf="step5">
        <div>
            <div class="account-logo">
                <a id="header-logo">
                    <img [defaultImage]="'assets/images/product/placeholder.jpg'" [lazyLoad]="logourl" class="img-fluid"
                        alt="logo">
                </a>
            </div>
            <h3 class="thanks-message">{{'thank you'|translate}}</h3>
            <p class="my-3 text-center activate-message">{{'activate account'|translate}}</p>
        </div>
        <button class="btn account-btn mb-2" (click)="doneSignUP()" [style.color]="calculateTextColor()">{{'button complete'|translate}}</button>
    </div>
    <div class="d-flex flex-column justify-content-between h-100" *ngIf="step6">
        <div>
            <div class="account-logo">
                <a id="header-logo">
                    <img [defaultImage]="'assets/images/product/placeholder.jpg'" [lazyLoad]="logourl" class="img-fluid"
                        alt="logo">
                </a>
            </div>
            <p class="my-3 text-center activate-message">{{'login account'|translate}}</p>
        </div>
        <button class="btn account-btn mb-2" (click)="doneSignUP()" [style.color]="calculateTextColor()">{{'buttoncomplete'|translate}}</button>
    </div>

</div>