<!--footer section -->
<footer [ngClass]="{'footer-padding' : this.paddingCondition }">

    <section class="section-b-space white-layout">


        <div class="container pr-3">
            <div class="row col-12 footer-theme partition-f">
                <div class="logo-section col-md-2 col-sm-4 col-12">
                    <div class="footer-contant logo-social-block">
                        <div class="footer-logo">
                            <img [src]="logourl" style="width: 80%;height:
                                40%;" alt="logo" (error)="handleImageError($event.target)">
                        </div>
                        <!-- <p>
                            {{contanctInfo['brief']?contanctInfo['brief']:""}}
                        </p> -->
                    </div>
                </div>
                <div class="download-section col-md-2 col-sm-4 col-12"
                    *ngIf="contanctInfo.app_url_android  || contanctInfo?.app_url_ios">
                    <div class="d-flex flex-column ">
                        <div class="footer-title ">
                            <h4 class="font-weight-bold">{{'download app' | translate }}</h4>
                        </div>
                        <div class="footer-contant">
                            <ul class="mt-4 play-ios-store-block d-flex flex-column">
                                <li class="w-75"  *ngIf="contanctInfo?.app_url_android">
                                    <a href="{{contanctInfo?.app_url_android}}" target="_blank">
                                        <img class="w-100" src="assets/images/download-google-play.webp"
                                            alt="Get Zahra EG App at Google Play Store">
                                    </a>
                                </li>
                                <li class="mt-2 w-75" *ngIf="contanctInfo?.app_url_ios">
                                    <a href="{{contanctInfo?.app_url_ios}}" target="_blank">
                                        <img class="w-100" src="assets/images/download-app-store.webp"
                                            alt="Get Zahra EG App at App Store">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="category-section col-md-2 col-sm-4 col-12" *ngIf="categories?.length > 0">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4 class="mr-2 position-relative font-weight-bold line">
                                {{'category' | translate }}
                            </h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li *ngFor="let category of categories |
                                    slice:0:5"><a
                                        [routerLink]="'shop/category/'+category.id+'/'+category.ser_name">{{category.ser_name}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="category-section col-md-2 col-sm-4 col-12"
                    *ngIf="allSupportAndBlog?.length >0 && ShowSupportBlock >0">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4 class="mr-2 position-relative font-weight-bold line">
                                {{'important link' | translate }}
                            </h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <ng-container *ngFor="let item of allSupportAndBlog">
                                    <li *ngIf="item.show_in_footer === '1'">
                                        <a
                                            [routerLink]="'/pages/category-support/'+item.id+'/'+item.name.split(' ').join('-')">
                                            {{ item.name }}
                                        </a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>

                    </div>
                </div>
                <!-- <div class="pages-section col-md-2 col-sm-4 col-12 " *ngIf="pages?.length > 0">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4 class="mr-2 position-relative font-weight-bold line">
                                {{'Pages' | translate }}
                            </h4>
                        </div>
                        <div class="footer-contant">

                        </div>
                    </div>
                </div> -->
                <div class="contactus-section col-md-2 col-sm-4 col-12"
                    *ngIf="mobileNumber !== null || contanctInfo['site_email']!==null">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4 class="mr-2 position-relative font-weight-bold line">
                                {{'Call Us' | translate }}
                            </h4>
                        </div>
                        <div class="footer-contant">
                            <ul class="flex-wrap contact-list">

                                <li class="align-items-baseline" *ngIf="mobileNumber !== null">
                                    <i class="fa fa-phone"></i>
                                    <a [href]="'tel:'+mobileNumber">
                                        {{'call-us' | translate }}: {{mobileNumber}}
                                    </a>
                                </li>

                                <li class="align-items-baseline" *ngIf="contanctInfo['site_email'] !== null">
                                    <i class="fa fa-envelope-o"></i><a [href]="'mailto:'+contanctInfo['email']">
                                        {{'Email'| translate }} :
                                        {{contanctInfo['site_email']}}</a>
                                </li>
                                <!-- <li class="align-items-baseline"><i class="fa fa-fax"></i>Fax: 123456</li> -->
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="social-section col-md-2 col-sm-4 col-12"
                    *ngIf="contanctInfo['facebook'] || contanctInfo['email'] || contanctInfo['twitter'] || contanctInfo['instagram'] || contanctInfo['snapchat'] || contanctInfo['tiktok']">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4 class="mr-2 position-relative font-weight-bold line">
                                {{'social media' | translate }}
                            </h4>
                        </div>
                        <div class="footer-content" [ngStyle]="{'float':Commen.isRtl?'right':'left'}"
                            style="top: 5px; position: relative; float: right;">
                            <ul class="icons-container d-flex">
                                <li class="my-2 social_icon "
                                    [ngStyle]="Commen.isRtl?{'margin':'.5rem'}:{'margin-left':'0px','margin-right':'.5rem'}"
                                    *ngIf="contanctInfo['facebook']">
                                    <a [href]="contanctInfo['facebook']" aria-label="Go to facebook"><i
                                            class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li class="m-2 social_icon" *ngIf="contanctInfo['email']">
                                    <a aria-label="Go to Gmail" [href]="'mailto:'+contanctInfo['email']"><i
                                            class="fa fa-envelope" aria-hidden="true"></i></a>
                                </li>
                                <li class="m-2 ml-0 social_icon" *ngIf="contanctInfo['twitter']">
                                    <a [href]="contanctInfo['twitter']" aria-label="Go to twitter"><i
                                            class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li class="m-2 mr-0 social_icon" *ngIf="contanctInfo['instagram']">
                                    <a [href]="contanctInfo['instagram']" aria-label="Go to instagram"><i
                                            class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                                <li class="m-2 social_icon" *ngIf="contanctInfo['snapchat']">
                                    <a [href]="contanctInfo['snapchat']" aria-label="Go to snapchat"><i
                                            class="fa fa-snapchat-ghost" aria-hidden="true"></i></a>
                                </li>
                                <li class="m-2 ml-0 social_icon" *ngIf="contanctInfo['tiktok']">
                                    <a [href]="contanctInfo['tiktok']" aria-label="Go to tiktok">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-tiktok" viewBox="0 0 16 16">
                                            <path
                                                d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="sub-footer">
        <div class="container">
            <div class="row">
                <div class="col-xl-8 col-md-8 col-sm-12">
                    <div class="footer-end">
                        <ul *ngIf="pages?.length > 0">

                            <li *ngFor="let page of pages"><a
                                    [href]="'pages/page/'+page.id+'/'+page.title.split(' ').join('-')">{{page.title}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-4 col-md-4 col-sm-12">
                    <div class="p-0 payment-card-bottom">
                        <ul>
                            <li>
                                <div>
                                    <img class="w-100" src="assets/images/icon/visa.png" alt="visa" width="26"
                                        height="26">
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img class="w-100" src="assets/images/icon/mastercard.png" alt="masterCard"
                                        width="26" height="26">
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img class="w-100" src="assets/images/icon/paypal.png" alt="payPaL" width="26"
                                        height="26">
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img class="w-100" src="assets/images/icon/american-express.png"
                                        alt="american-express" width="26" height="26">
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img class="w-100" src="assets/images/icon/discover.png" alt="discover" width="26"
                                        height="26">
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer section end -->