import { CommonService } from "src/app/shared/services/common.service";
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Inject,
  ViewEncapsulation,
  TemplateRef,
  AfterViewInit,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ProductDetailsMainSlider,
  ProductDetailsThumbSlider,
  ProductSlider,
} from "../../../../shared/data/slider";
import { Product } from "../../../../shared/classes/product";
import { ProductService } from "../../../../shared/services/product.service";
import { SizeModalComponent } from "../../../../shared/components/modal/size-modal/size-modal.component";
import { ParamMap, NavigationEnd } from "@angular/router";

import { UserService } from "../../../../shared/services/user.service";
import { ToastrService } from "ngx-toastr";
import { DOCUMENT, Location } from "@angular/common";

import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";

import SwiperCore, {
  FreeMode,
  Navigation,
  Pagination,
  SwiperOptions,
  Thumbs,
} from "swiper";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { DomSanitizer, Meta, SafeHtml, Title } from "@angular/platform-browser";
import { type } from "os";
import { CategoriesService } from "src/app/shared/services/categories";
import { SwiperComponent } from "swiper/angular";
import { OwlOptions } from "ngx-owl-carousel-o";
import { HttpErrorResponse } from "@angular/common/http";
import { MasterApiService } from "src/app/shared/services/master-api.service";

// install Swiper modules
SwiperCore.use([FreeMode, Navigation, Thumbs, Pagination]);

declare var jquery: any;
declare var $: any;
// declare let fbq: Function;

@Component({
  selector: "app-product-left-sidebar",
  templateUrl: "./product-left-sidebar.component.html",
  styleUrls: ["./product-left-sidebar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ProductLeftSidebarComponent implements OnInit, OnDestroy {
  frontLang: string = localStorage.getItem("front-lang");
  @ViewChild("mainProductSwiper") mainProductSwiper?: SwiperComponent;
  isFavourit: boolean = false;
  thumbsSwiper: any;

  relatedProductsSliderConfig: SwiperOptions = {
    slidesPerView: "auto",
    spaceBetween: 20,
    // navigation: true,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
  };

  public ProductSliderConfig: any = ProductSlider;
  closeResult = "";

  schema = {
    "@context": "http://schema.org",
    "@type": "Product",
    brand: {
      "@type": "Brand",
    },
    review: {
      "@type": "Review",
      author: {
        "@type": "Person",
        name: localStorage.getItem("front_name"),
      },
    },
  };

  public product: any;
  public productimages: any[] = [];
  public counter: number = 1;
  public activeSlide: any = 0;
  public selectedSize: any;
  public mobileSidebar: boolean = false;
  public quantatyMorThanStor: boolean = false;
  public category_id = "";
  public relatedproducts: any[] = [];

  public settings: any;

  public productFormShapesDetails: any = [];
  unitPricefor1quantity: string = "";
  quantity_discount: any = [];
  related_product: any = [];
  similar_product: any = [];
  multi_select: string[] = [];
  userLogedIn: boolean = false;
  deviceID: string;
  userNotLogedInError: boolean = false;
  showPrice: boolean = false;
  public productStors: any[] = [];
  public selectedStore: any = "";
  public errorselectedStore: boolean = false;
  public imagetrademarkurl = "";
  idtime: any;
  timesnum = 0;
  urlimage;
  importApiImage;
  imageurl = "";
  logourl = "";
  tabId = "general";

  // FREQUENTLY BOUGHT BLOCK VARIABLES
  freqBoughtCheckboxList: any[] = [false, false, false];

  // PACKAGE DATA
  packageData: any;
  newPackagePrice: any;
  selectedProductsCount: number = 0;
  totalPrice: number = 0;

  @Input("loadVideo") loadVideo: boolean = false;

  @ViewChild("sizeChart") SizeChart: SizeModalComponent;

  @Input() currency: any = this.productService.Currency; // Default Currency
  public ProductDetailsMainSliderConfig: any = ProductDetailsMainSlider;
  public ProductDetailsThumbConfig: any = ProductDetailsThumbSlider;
  noteLoader: boolean = true;
  note_id: number;
  @ViewChild("templateBottomSheet") TemplateBottomSheet: TemplateRef<any>;
  description: string = "";
  keyWords: string = "";
  Title: string = "";
  imgUrl: string = "";
  brief_description: any;
  meta_description: string = "";
  meta_title: string = "";

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: [
      '<i class="fa fa-chevron-left"></i>',
      '<i class="fa fa-chevron-right"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      200: {
        items: 2,
      },
      400: {
        items: 3,
      },
    },
    nav: true,
  };

  ProductQuantity: number = 0;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    public user: UserService,
    private router: Router,
    public Common: CommonService,
    public productService: ProductService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private bottomSheet: MatBottomSheet,
    private meta: Meta,
    private location: Location,
    private sanitizer: DomSanitizer,
    private categories: CategoriesService,
    private title: Title,
    private MasterApiService: MasterApiService
  ) {
    this.removeHomeMetaTags();
    this.productFormShapesDetails.loading = true;
    this.getAllContanctInfo();
    this.urlimage = this.Common._ImageUrl + "products/";
    this.importApiImage = this.Common._ImageUrl + "importapi/";
    this.imagetrademarkurl = this.Common._ImageUrl + "trademarks/";
    // this.route.data.subscribe(response => this.product = response.data );
    this.imageurl = this.Common._ImageUrl + "front_images/";
  }

  addPackageToCart(product1: any, product2: any, product3: any) {
    this.addToCart(product1);
    this.addToCart(product2);
    this.addToCart(product3);
    console.log(product1, product2, product3);
  }

  freqCheck(index) {
    this.freqBoughtCheckboxList[index] = !this.freqBoughtCheckboxList[index];
    // console.log("frequent checklist ", this.freqBoughtCheckboxList);
  }

  checkFreqSelected(event, index) {
    if (event.currentTarget.checked) {
      this.freqBoughtCheckboxList[index] = true;
    } else {
      this.freqBoughtCheckboxList[index] = false;
    }
  }
  handleImageError(e) {
    e.src = "assets/images/product/placeholder2.jpg";
  }
  tabChange(id: any) {
    this.tabId = id;
  }
  transform(html: string): string {
    return html.replace(/<[^>]*>/g, "");
  }
  checkOuth() {
    if (this.user.getToken() !== null || this.deviceID) {
      // console.log(this.deviceID);
      // console.log(localStorage.getItem('Device-Id'));
      this.userLogedIn = true;
    }
    if (this.user.getShowPrice() == "1") {
      this.showPrice = true;
    }
  }

  breadCrumblist: any;
  breadCrumbProductName: string = "";
  optionsData: any = [];
  optionChekced: number = 0;
  optionItemData: any;
  ngOnInit(): void {
    // Loadding on
    // console.log('ngOnInit');
    this.deviceID = localStorage.getItem("Device-Id");

    this.checkOuth();

    this.route.params.subscribe((params: ParamMap) => {
      const product_id = params["slug"];
      // this.title.setTitle(params["name"]);

      // setTimeout(() => {
      this.productService.getProductTitle(product_id).subscribe((data) => {
        if (!data.success) {
          this.router.navigate(["/not-found"]);
          return;
        }
        console.log(data);

        this.meta_description = data.data.meta_description
          ? this.transform(data.data.meta_description)
          : this.transform(data.data.description);
        this.meta_title = data.data.meta_title
          ? data.data.meta_title
          : data.data.name;
        this.Common.setMeta("", this.meta_description, true, "og:description");
        this.Common.setMeta("", this.meta_title, true, "og:title");
        this.Common.setMeta("description", this.meta_description);
        this.Common.setMeta("keywords", data.data.key_words);
      });
      // }, 1000);

      this.productService.getProductDetails(product_id).subscribe((data) => {
        if (!data.success) {
          this.router.navigate(["/not-found"]);
          return;
        }
        // Loadding Off
        this.product = data["data"];
        console.log(this.product);
        
        this.optionsData = data.data.stores;
        if (this.optionsData.length > 0) {
          this.optionItemData = this.optionsData[0];
        } else {
          this.optionItemData = [];
        }
        this.quantity_discount = [];
        this.related_product = [];
        this.similar_product = [];
        this.ProductQuantity = 0;
        this.Common.setTitle(
          this.product.meta_title ? this.product.meta_title : this.product.name,
          false,
          false,
          "product"
        );

        this.title.setTitle(
          this.product.meta_title
            ? localStorage.getItem("front_name")+' - ' + this.product.meta_title
            : localStorage.getItem("front_name") +' - '+ this.product.name
        );
        this.Title = !this.product.meta_title
          ? this.product.name
          : this.product.meta_title;

        this.brief_description = this.product?.brief_description;
        // console.log(this.product.name.split(' ').join('-'));

        if (this.product.images.length > 0) {
          this.productimages = this.product.images;
          this.imgUrl = this.urlimage + this.productimages[0].original_name;
        }
        // GET PACKAGE DATA
        if (this.product["package_data"]) {
          this.packageData = this.product["package_data"];
          // console.log("PRODUCT DATA package data", this.packageData);
          this.newPackagePrice =
            this.packageData.total_price * 1 - this.packageData.discount * 1;
        }

        this.schema["name"] = this.product["name"];
        this.breadCrumbProductName = this.product["name"].split(" ").join(" ");

        if (this.product["images"].length) {
          this.schema["image"] =
            this.urlimage + this.product["images"][0]["name"];
        }

        this.schema["sku"] = this.product["product_barcode"];

        this.schema["brand"]["name"] = this.product["trademark_title"];
        this.schema["brand"]["logo"] =
          this.imagetrademarkurl + this.product["trademark_image"];
        this.schema["description"] = this.product["description_strip_tags"];
        this.description =
          this.product["meta_description"] == null
            ? this.product["description"]
            : this.product["meta_description"];
        this.keyWords = this.product["key_words"];
        this.productFormShapesDetails = this.product["form_shapes_Details"];
        this.getProductsRelated(this.product["id"]);
        if (this.product["store_amounts"] > 0 && +this.product["price"] > 0) {
          this.getProductQuantityDiscount(this.product["id"]);
          this.getProductSimilar(this.product["id"]);
        }

        for (let i = 0; i < this.productFormShapesDetails.length; i++) {
          if (this.productFormShapesDetails[i].type == "m_select") {
            this.multi_select[i] =
              this.productFormShapesDetails[i].form_value.split("-");
          }
        }
        console.log(this.productFormShapesDetails);
        this.productFormShapesDetails.loading = false;

        // SET TITLE
        const currentUrl = this.router.url.split("/");
        currentUrl[4] =
          this.product.meta_url == null
            ? this.removeBrackets(this.product.name)
            : this.product.meta_url.split(" ").join("-");
        const totalUrl = currentUrl.join("/");
        const baseUrl = window.location.origin;

        //console.log(totalUrl);

        //console.log(desc);
        //console.log(this.product['meta_description']);

        const fullUrl = baseUrl + totalUrl;
        //console.log(fullUrl);

        // this.Common.setMeta("description",desc );
        // this.Common.setMeta("keywords", this.keyWords);
        //this.Common.setMeta("twitter:url", fullUrl);

        this.Common.setMeta("", this.document.URL, true, "og:url");
        //this.Common.setMeta("twitter:title",this.Common.mainTitle + ' - ' + this.Title);
        //this.Common.setMeta("twitter:description",this.description);
        this.Common.setMeta("", this.imgUrl, true, "og:image");
        this.Common.setMeta("", "product", true, "og:type");

        // this.meta.addTag({name : 'keywords' , content : this.keyWords });
        // this.meta.addTag({name : 'description' , content : desc });
        this.meta.addTag({
          property: "product:price:amount",
          content: this.product["price"]?.toString(),
        });
        this.meta.addTag({
          property: "product:price:currency",
          content: this.Common.currencyType,
        });
        this.meta.addTag({
          property: "product:sale_price:amount",
          content: this.product["price"]?.toString(),
        });
        this.meta.addTag({
          property: "product:sale_price:currency",
          content: this.Common.currencyType,
        });
        this.meta.addTag({
          property: "product:brand",
          content:
            this.product["trademark_title"] == null
              ? ""
              : this.product["trademark_title"],
        });
        this.meta.addTag({
          property: "product:retailer_item_id",
          content: this.product["id"].toString(),
        });
        this.meta.addTag({
          property: "product:availability",
          content:
            this.product["store_amounts"] > 0 ? "in stock" : "out of stock",
        });
        this.category_id = this.product["product_services"][0]["service_id"];
        this.categories.getBreadcrumb(this.category_id).subscribe((cate) => {
          if (cate.success) {
            this.breadCrumblist = cate.data;
            // console.log(this.breadCrumblist);
          }
        });

        // this.productStors = this.product["stores"];
        this.productStors = this.product["stores"].filter(
          (ele) => ele.store_amounts_product > 0
        );

        // console.log("THIS ARE THE PRODUCTS", this.productStors);

        if (this.productStors.length == 1) {
          this.product["product_type_id"] =
            this.productStors[0]["product_type_id"];
          this.selectedStore = this.productStors[0]["product_type_id"];
        }

        if (this.category_id != "") {
          this.getRelatedProduct(this.category_id);
        }
      });

      this.productService
        .addBrowseProductHistory(product_id)
        .subscribe((data) => {
          // console.log("browse ProductHistory", data);
        });
    });
  }

  getProductQuantityDiscount(Product_id): void {
    this.productService.getProductQuantityDiscount(Product_id).subscribe({
      next: (res) => {
        if (res.success) {
          console.log(res);
          this.quantity_discount = res.data;
          // this.quantity_discount.sort(
          //   (a, b) => parseInt(a.quantity) - parseInt(b.quantity)
          // );
          console.log("quantity_discount", this.quantity_discount);
          this.quantity_discount.forEach((element) => {
            if (parseInt(element.quantity) == 1) {
              this.unitPricefor1quantity = element.unit_price;
              console.log(this.unitPricefor1quantity);
            }
          });
          if (this.quantity_discount.length > 0) {
            for (
              let index = 0;
              index < this.quantity_discount.length;
              index++
            ) {
              if (
                parseInt(this.quantity_discount[index].quantity) <=
                  this.product.stores[0]["store_amounts_product"] &&
                parseInt(this.quantity_discount[index].quantity) > 1
              ) {
                this.ProductQuantity++;
              }
            }
          }
        }
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      },
    });
  }
  getProductSimilar(Product_id): void {
    this.productService.getProductSimilar(Product_id).subscribe({
      next: (res) => {
        if (res.success) {
          console.log(res);
          this.similar_product = res.data;
          this.similar_product.forEach((product) => {
            product.checked = true;
          });

          this.calculateTotalPrice();
          console.log("similar_product", this.similar_product);
        }
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      },
    });
  }
  getProductsRelated(Product_id): void {
    this.productService.getProductsRelated(Product_id).subscribe({
      next: (res) => {
        if (res.success) {
          console.log(res);
          this.related_product = res.data;
          console.log("related_product", this.related_product);
        }
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      },
    });
  }
  removeBrackets(str: string): string {
    return str.replace(/[\(/%)]/g, "");
  }
  // removeBrackets(str: string): string {
  //   if (str != null) {
  //     return str?.replace(/[\()/%]/g, "");
  //   }
  // }

  removeHomeMetaTags() {
    this.meta.removeTag('name="description"');
    this.meta.removeTag('name="keywords"');
    this.meta.removeTag('property="og:description"');
    this.meta.removeTag('property="og:title"');
    // this.meta.removeTag('name="twitter:title"');
    // this.meta.removeTag('name="twitter:description"');
    // this.meta.removeTag('name="twitter:url"');
    this.meta.removeTag('property="og:url"');
    this.meta.removeTag('property="product:pretax_price:currency"');
    this.meta.removeTag('property="product:price:amount"');
    this.meta.removeTag('property="product:price:currency"');
    this.meta.removeTag('property="product:sale_price:amount"');
    this.meta.removeTag('property="product:sale_price:currency"');
    this.meta.removeTag('property="product:brand"');
    this.meta.removeTag('property="product:retailer_item_id"');
    this.meta.removeTag('property="product:availability"');
    this.meta.removeTag('property="og:image"');
  }

  openNotifyMeModal(content, productId) {
    // this.cartModal = true;

    if (localStorage.getItem("clientToken")) {
      // console.log("product id notify modal ", productId);
      // this.productIdToListenToAvailability = productId;

      this.modalService
        .open(content, { ariaLabelledBy: "modal-basic-title" })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    } else {
      this.toastrService.warning(
        this.translateService.instant(
          "Please log in or register to get notified when this product is available"
        )
      );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  getAllContanctInfo() {
    // this.Common.getAllContanctInfo().subscribe((data) => {
    //   //console.log'all contact info ', data);
    //   if (data.success) {
    //     this.settings = data.data;
    //     this.logourl = this.imageurl + this.settings["front_logo"];
    //     if (this.settings.full_description_web != "1") {
    //       this.tabId = "description";
    //     }
    //     localStorage.setItem(
    //       "paymentInfo",
    //       JSON.stringify(this.Common.getAllPaymentMethods(data.data))
    //     );
    //   }
    // });

    this.MasterApiService.getCurrentDataContact().subscribe(
      (dataContact) => {
        if (!dataContact) {
          console.error("Data contact information is undefined or null");
          return;
        }

        this.settings = dataContact;
        this.logourl = this.imageurl + this.settings["front_logo"];
        if (this.settings.full_description_web != "1") {
          this.tabId = "description";
        }
        localStorage.setItem(
          "paymentInfo",
          JSON.stringify(this.Common.getAllPaymentMethods(dataContact))
        );
      },
      (error) => {
        console.error("Error fetching contact info", error);
      }
    );
  }

  getRelatedProduct(category_id) {
    this.productService
      .getProductsPayParams(category_id)
      .subscribe((products) => {
        //console.log(products);

        if (products.data) {
          this.relatedproducts = products.data;
          // console.log("related products", this.relatedproducts);
        } else {
          // console.log("no data in related products");
        }
      });
  }
  getDiscountPercentage(last_price, price) {
    var l_p = parseInt(last_price);
    var p = parseInt(price);
    var res = ((l_p - p) / l_p) * 100;
    return Math.round(res);
  }
  // Get Product Color
  // Color(variants) {
  //   const uniqColor = []
  //   for (let i = 0; i < Object.keys(variants).length; i++) {
  //     if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
  //       uniqColor.push(variants[i].color)
  //     }
  //   }
  //   return uniqColor
  // }

  // Get Product Size
  Size(variants) {
    const uniqSize = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqSize.indexOf(variants[i].size) === -1 && variants[i].size) {
        uniqSize.push(variants[i].size);
      }
    }
    return uniqSize;
  }

  selectSize(size) {
    this.selectedSize = size;
  }

  // Increament
  increment() {
    if (this.counter < this.optionItemData["store_amounts_product"])
      this.counter++;
  }

  // Decrement
  decrement() {
    if (this.counter > 1) this.counter--;
    // this.Common.UpdatedSetting.next("decrement");
  }

  // ADD TO CART 1
  addToCart1(product: any) {
    this.productService.addToCart1(product);
  }

  chooseOptions(index, product) {
    this.counter = 1;
    this.optionChekced = index;
    this.optionItemData = product;
    console.log(this.optionItemData);
  }

  // Add to cart
  async addToCart(product: any) {
    console.log("new product", product);
    let selectedStore = "";
    let selectedStoreName = "";
    let errorselectedStore;
    let quantatyMorThanStor;
    if (this.userLogedIn == true) {
      errorselectedStore = false;
      quantatyMorThanStor = false;
      // let productStors = product["stores"].filter(
      //   (ele) => ele.store_amounts_product > 0
      // );

      if (this.optionItemData) {
        // product["product_type_id"] = productStors[0]["product_type_id"];
        // selectedStore = productStors[0]["product_type_id"];
        product["store_id"] = this.optionItemData["store_id"];
        selectedStore = this.optionItemData["store_id"];
        selectedStoreName = this.optionItemData["name"];
      }
      console.log(selectedStore, selectedStoreName);
      if (selectedStore == "") {
        console.log("Product Add To Cart => ", product);
        if (localStorage.getItem("front-lang") == "en") {
          this.toastrService.error("Product not available");
        } else {
          this.toastrService.error(" المنتح غير متوفر ");
        }
        errorselectedStore = true;
      } else {
        product["selected_store"] = true;
        product["store_id"] = selectedStore;
        product.quantity = this.counter > 1 ? this.counter : 1;
        console.log("Product Quantity " + product.quantity);
        for (let i = 0; i < this.optionsData.length; i++) {
          if (
            this.optionsData[i].store_id == selectedStore &&
            this.optionsData[i].name == selectedStoreName
          ) {
            if (
              product.quantity > this.optionsData[i]["store_amounts_product"]
            ) {
              quantatyMorThanStor = true;
              if (localStorage.getItem("front-lang") == "en") {
                this.toastrService.error("Quantity is not for this item");
              } else {
                this.toastrService.error("الكمية غير متاحة لهذا الصنف");
              }
              errorselectedStore = true;
              return;
            }
          }
        }
        product["stores"] = this.optionsData;
        const toast = await this.productService.addToCart(
          product,
          this.optionItemData
        );
        if (toast) {
          if (localStorage.getItem("front-lang") == "en") {
            this.toastrService.success("Product has been added in cart.");
          } else {
            this.toastrService.success("تم إضافة المنتج الى السلة ");
          }
        }
        return true;
      }
    } else {
      this.toastrService.error("يرجى تسجيل الدخول");
      this.userNotLogedInError = true;
      return false;
    }
  }
  // Buy Now
  async buyNow(product: any) {
    let userToken = localStorage.getItem("clientToken");
    if (userToken !== null && userToken !== "" && this.user.getCurrentUser()) {
      this.Common.UpdatedChecckOut.next("added");
      let status = await this.addToCart(product);
      console.log(status);

      if (status) {
        this.router.navigate(["/shop/checkout"]);
      }
    } else {
      this.router.navigate(["pages/login"]);
    }
  }

  // isloading: boolean = false;
  // async addSimilarToCart(): Promise<void> {
  //   this.isloading = true;
  //   for (const [index, product] of this.similar_product.entries()) {
  //     if (product.checked) {
  //       this.isloading = false;
  //       await this.addProductToCart(product);
  //     }
  //   }
  // }

  isloading: boolean = false;

  async addSimilarToCart(): Promise<void> {
    this.isloading = true;
    const checkedProducts = this.similar_product.filter(
      (product) => product.checked
    );
    let anyProductAdded = false;
    for (const product of checkedProducts) {
      const toast = await this.addProductToCart(product);
      if (toast) {
        anyProductAdded = true;
      }
    }
    if (anyProductAdded) {
      if (localStorage.getItem("front-lang") == "en") {
        this.toastrService.success("Product has been added in cart.");
      } else {
        this.toastrService.success("تم إضافة المنتج الى السلة ");
      }
    }
    this.isloading = false;
  }

  // async addSimilarProductToCart(product: any, toast: boolean = true) {
  //  let selectedStore = ""
  //   let errorselectedStore
  //   let quantatyMorThanStor;
  //       if (this.userLogedIn == true) {
  //         errorselectedStore = false;
  //         quantatyMorThanStor = false;
  //         let productStors = product["stores"].filter(
  //                   (ele) => ele.store_amounts_product > 0
  //                 );
  //                 if (productStors.length == 1) {
  //                   product["product_type_id"] =
  //                     productStors[0]["product_type_id"];
  //                   selectedStore =
  //                     productStors[0]["product_type_id"];
  //                 }
  //         if (selectedStore == "") {
  //           console.log("Product Add To Cart => ", product);
  //           errorselectedStore = true;
  //         } else {
  //           product["selected_store"] = true;
  //           product["product_type_id"] = selectedStore;
  //           product.quantity = this.counter > 1 ? this.counter : 1;
  //           console.log("Product Quantity " + product.quantity);
  //           for (let i = 0; i < productStors.length; i++) {
  //             if (productStors[i].product_type_id == selectedStore) {
  //               if (
  //                 product.quantity >
  //                 productStors[i]["store_amounts_product"]
  //               ) {
  //                 quantatyMorThanStor = true;
  //                 return;
  //               }
  //             }
  //           }
  //           product["stores"] = productStors;
  //           await this.productService.addToCart(product, toast);
  //           return true;
  //         }
  //       } else {
  //         this.toastrService.error("يرجى تسجيل الدخول");
  //         this.userNotLogedInError = true;
  //         return false;
  //       }

  // }

  addProductDiscountToCart(product) {
    console.log("discount product", product);
    this.addProductToCart(product)
      .then((toast) => {
        if (toast) {
          if (localStorage.getItem("front-lang") == "en") {
            this.toastrService.success("Product has been added in cart.");
          } else {
            this.toastrService.success("تم إضافة المنتج الى السلة ");
          }
        }
      })
      .catch((error) => {
        console.error("Error adding product to cart:", error);
      });
  }

  async addProductToCart(product: any) {
    let selectedStore = "";
    let errorselectedStore;
    let quantatyMorThanStor;
    if (this.userLogedIn == true) {
      errorselectedStore = false;
      quantatyMorThanStor = false;
      let productStors = product["stores"].filter(
        (ele) => ele.store_amounts_product > 0
      );
      if (productStors.length == 1) {
        product["product_type_id"] = productStors[0]["product_type_id"];
        selectedStore = productStors[0]["product_type_id"];
      }
      if (
        selectedStore == "" ||
        product.quantity > product["stores"][0]["store_amounts_product"]
      ) {
        console.log("Product Add To Cart => ", product);
        if (localStorage.getItem("front-lang") == "en") {
          this.toastrService.error("product not available");
        } else {
          this.toastrService.error("المنتج غير متوفر ");
        }
        errorselectedStore = true;
      } else {
        product["selected_store"] = true;
        product["product_type_id"] = selectedStore;
        console.log("Product Quantity " + product.quantity);
        for (let i = 0; i < productStors.length; i++) {
          if (productStors[i].product_type_id == selectedStore) {
            if (product.quantity > productStors[i]["store_amounts_product"]) {
              quantatyMorThanStor = true;
              return;
            }
          }
        }
        product["stores"] = productStors;

        await this.productService.addToCart(product);
        // if (toast) {
        //   if (localStorage.getItem("front-lang") == "en") {
        //     this.toastrService.success("Product has been added in cart.");
        //   } else {
        //     this.toastrService.success("تم إضافة المنتج الى السلة ");
        //   }
        // }
        return true;
      }
    } else {
      this.toastrService.error("يرجى تسجيل الدخول");
      this.userNotLogedInError = true;
      return false;
    }
  }

  // Add to Wishlist
  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
    this.isFavourit = true;
  }

  // Toggle Mobile Sidebar
  toggleMobileSidebar() {
    this.mobileSidebar = !this.mobileSidebar;
  }

  ngAfterViewChecked() {
    // console.log("ngAfterViewChecked");
  }

  getNoteDetails(noteID: number): void {
    this.noteLoader = true;
    this.Common.noteOn = true;
    this.note_id = noteID;
    const bottomSheetRef = this.bottomSheet.open(this.TemplateBottomSheet, {
      hasBackdrop: false,
    });

    this.Common.getNoteDetails(noteID).subscribe((res) => {
      setTimeout(() => {
        $("#note_description").html(res.data.description);
      }, 500);
      setTimeout(() => {
        this.noteLoader = false;
      }, 500);
    });

    bottomSheetRef.afterDismissed().subscribe(() => {
      this.Common.noteOn = false;
    });
  }

  closeTemplateSheetMenu() {
    this.Common.noteOn = false;
    this.bottomSheet.dismiss();
  }
  ngOnDestroy(): void {
    this.removeHomeMetaTags();
  }

  onCarouselTranslate(event: any) {
    const currentPosition = event["page"]["index"];
    const totalItems = event["page"]["count"];
    if (currentPosition === 0) {
      document.querySelector(".owl-prev").classList.add("hide-arrow");
    } else {
      document.querySelector(".owl-prev").classList.remove("hide-arrow");
    }
    if (currentPosition === totalItems - 1) {
      document.querySelector(".owl-next").classList.add("hide-arrow");
    } else {
      document.querySelector(".owl-next").classList.remove("hide-arrow");
    }
  }

  similarProductsSliderConfig: SwiperOptions = {
    slidesPerView: "auto",
    spaceBetween: 0,
    navigation: true,
    scrollbar: { draggable: true },
  };

  toggleProductChecked(index: number) {
    this.similar_product[index].checked = !this.similar_product[index].checked;
    this.calculateTotalPrice();
  }

  calculateTotalPrice() {
    this.totalPrice = 0;
    this.selectedProductsCount = 0;
    this.similar_product.forEach((product) => {
      if (product.checked) {
        this.totalPrice += Number(product.price);
        this.selectedProductsCount++;
      }
    });
    console.log(this.totalPrice);
  }
  calculateTextColor(): string {
    return this.Common.tinyColor("var(--theme-default)");
  }

  isMobileLogin: boolean = true;
  isEmailLogin: boolean = false;

  toggleLoginType(isEmail: boolean) {
    this.isMobileLogin = isEmail;
    this.isEmailLogin = !isEmail;
  }
}
