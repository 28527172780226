import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class KnetPaymentService {
  private knetPaymentUrl = 'https://kpaytest.com.kw/kpg/PaymentHTTP.htm?param=paymentInit'; // KNET test URL

  constructor(private http: HttpClient) {}

  initializePayment(data: any) {
    const requestBody = this.constructRequestPayload(data);
    return this.http.post(this.knetPaymentUrl, requestBody, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      responseType: 'text',
    });
  }

  private constructRequestPayload(data: any) {
    return `id=${data.terminalId}&password=${data.terminalPassword}&action=1&currency=414&amt=${data.amount}&trackid=${data.trackId}&responseURL=${data.responseURL}&errorURL=${data.errorURL}`;
  }
}
